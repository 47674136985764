(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-bet-proposal/assets/javascripts/services/update-bet-proposal.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-bet-proposal/assets/javascripts/services/update-bet-proposal.js');
"use strict";


const {
  jupiter
} = svs.core;

const {
  resolveProductId,
  productIds: {
    RACING,
    BOMBEN,
    FULLTRAFF
  }
} = svs.utils.products;

const {
  helpers: betProposalHelpersMockable
} = svs.components.marketplaceData.betProposal;
const {
  helpers: createBetProposalHelpersMockable
} = svs.components.marketplaceData.createBetProposal;

const updateBetProposal = (activatedDrawId, betProposal, betProposalIsPrivate, product, callback) => {
  const productId = resolveProductId(product);
  let deNormalizedBetProposal;
  switch (productId) {
    case RACING:
      deNormalizedBetProposal = createBetProposalHelpersMockable.deNormalizeBetProposalRacing(betProposal);
      break;
    case BOMBEN:
      deNormalizedBetProposal = createBetProposalHelpersMockable.deNormalizeBetProposalBomben(betProposal);
      break;
    case FULLTRAFF:
      deNormalizedBetProposal = createBetProposalHelpersMockable.deNormalizeBetProposalFulltraff(betProposal);
      break;
    default:
      deNormalizedBetProposal = createBetProposalHelpersMockable.deNormalizeBetProposal(betProposal);
  }
  jupiter.put({
    path: "/marketplace/1/activateddraws/".concat(activatedDrawId, "/preliminarywagers?isPrivate=").concat(Boolean(betProposalIsPrivate)),
    data: {
      rows: deNormalizedBetProposal
    }
  }, _ref => {
    let {
      savedWager
    } = _ref;
    return callback(null, betProposalHelpersMockable.normalizeBetProposal(savedWager.rows, product));
  }, error => callback(error.responseJSON.error));
};

setGlobal('svs.components.marketplaceData.createBetProposal.services.updateBetProposal', updateBetProposal);

 })(window);