(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-bet-proposal/assets/javascripts/create-bet-proposal-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-bet-proposal/assets/javascripts/create-bet-proposal-actions.js');
"use strict";


const {
  getRowId: getBetProposalRowId
} = svs.components.marketplace.helpers;
const {
  services
} = svs.components.marketplaceData.createBetProposal;
const {
  dialogWrapper
} = svs.components.marketplaceData;
const CLEAR_ROW = 'CREATE_BET_PROPOSAL/CLEAR_ROW';
const TOGGLE_OUTCOME = 'CREATE_BET_PROPOSAL/TOGGLE_OUTCOME';
const BET_PROPOSAL_UPDATE_INIT = 'CREATE_BET_PROPOSAL/BET_PROPOSAL_UPDATE_INIT';
const BET_PROPOSAL_UPDATE_SUCCESS = 'CREATE_BET_PROPOSAL/BET_PROPOSAL_UPDATE_SUCCESS';
const BET_PROPOSAL_UPDATE_FAILURE = 'CREATE_BET_PROPOSAL/BET_PROPOSAL_UPDATE_FAILURE';
const BET_PROPOSAL_CREATE_INIT = 'CREATE_BET_PROPOSAL/BET_PROPOSAL_CREATE_INIT';
const BET_PROPOSAL_CREATE_SUCCESS = 'CREATE_BET_PROPOSAL/BET_PROPOSAL_CREATE_SUCCESS';
const BET_PROPOSAL_CREATE_FAILURE = 'CREATE_BET_PROPOSAL/BET_PROPOSAL_CREATE_FAILURE';
const clearRow = (activatedDrawId, productName, drawNumber, groupId) => ({
  type: CLEAR_ROW,
  activatedDrawId,
  productName,
  drawNumber,
  groupId
});
const toggleOutcome = (activatedDrawId, event, productName, drawNumber, groupId, participantNumber) => {
  const {
    eventNumber,
    outcomeNumber
  } = event;
  return {
    type: TOGGLE_OUTCOME,
    activatedDrawId,
    eventNumber,
    outcomeNumber,
    productName,
    drawNumber,
    groupId,
    participantNumber
  };
};
const createBetProposal = (productName, drawNumber, groupId, activatedDrawId, betProposalIsPrivate) => (dispatch, getState) => {
  const rowId = getBetProposalRowId(productName, drawNumber, groupId);
  const createBetProposal = (getState().CreateBetProposal[rowId] || {}).data || {};
  dispatch({
    type: BET_PROPOSAL_CREATE_INIT,
    productName,
    drawNumber,
    groupId,
    betProposalIsPrivate
  });
  services.updateBetProposal(activatedDrawId, createBetProposal, betProposalIsPrivate, productName, (error, betProposal) => {
    if (error) {
      svs.components.marketplaceData.errorCodeHandler.getErrorMessage(error.code, err => {
        dispatch({
          type: BET_PROPOSAL_CREATE_FAILURE,
          productName,
          drawNumber,
          groupId,
          error
        });
        dispatch(dialogWrapper.actions.showDialog({
          title: err.message,
          type: 'error'
        }));
      });
    } else {
      dispatch({
        type: BET_PROPOSAL_CREATE_SUCCESS,
        productName,
        drawNumber,
        groupId,
        activatedDrawId,
        betProposal
      });
    }
  });
};
const updateBetProposal = function (activatedDrawId, groupId) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return (dispatch, getState) => {
    var _getState;
    const createBetProposal = (getState().CreateBetProposal[activatedDrawId] || {}).data || {};
    const currentBetProposal = (getState().BetProposal[activatedDrawId] || {}).data || {};
    const betProposalIsPrivate = options === null || options === void 0 ? void 0 : options.betProposalIsPrivate;
    const product = options === null || options === void 0 ? void 0 : options.product;
    const isPrivateIsUpdated = ((_getState = getState()) === null || _getState === void 0 || (_getState = _getState.BetProposal[activatedDrawId]) === null || _getState === void 0 ? void 0 : _getState.betProposalIsPrivate) !== betProposalIsPrivate;
    if (JSON.stringify(createBetProposal) === JSON.stringify(currentBetProposal) && !isPrivateIsUpdated) {
      return;
    }
    dispatch({
      type: BET_PROPOSAL_UPDATE_INIT,
      activatedDrawId
    });
    services.updateBetProposal(activatedDrawId, createBetProposal, betProposalIsPrivate, product, (error, betProposal) => {
      if (error) {
        dispatch({
          type: BET_PROPOSAL_UPDATE_FAILURE,
          groupId,
          activatedDrawId,
          error
        });
        dispatch(dialogWrapper.actions.showDialog({
          title: 'Vi kunde tyvärr inte spara radskissen, försök igen senare',
          type: 'error'
        }));
        return;
      }
      dispatch({
        type: BET_PROPOSAL_UPDATE_SUCCESS,
        activatedDrawId,
        betProposal
      });
      if (options.alertChanges) {
        dispatch(dialogWrapper.actions.showDialog({
          title: 'Ändringarna har sparats.',
          icon: 'checkmark'
        }));
      }
    });
  };
};
setGlobal('svs.components.marketplaceData.createBetProposal.actions', {
  CLEAR_ROW,
  TOGGLE_OUTCOME,
  clearRow,
  toggleOutcome,
  BET_PROPOSAL_CREATE_INIT,
  BET_PROPOSAL_CREATE_SUCCESS,
  BET_PROPOSAL_CREATE_FAILURE,
  createBetProposal,
  BET_PROPOSAL_UPDATE_INIT,
  BET_PROPOSAL_UPDATE_SUCCESS,
  BET_PROPOSAL_UPDATE_FAILURE,
  updateBetProposal
});

 })(window);