(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-bet-proposal/assets/javascripts/helpers/de-normalize-bet-proposal.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-bet-proposal/assets/javascripts/helpers/de-normalize-bet-proposal.js');
"use strict";


const deNormalizeBetProposal = betProposal => Object.keys(betProposal).map(eventNumber => ({
  eventNumber,
  choices: [Boolean(betProposal[eventNumber][1]), Boolean(betProposal[eventNumber][2]), Boolean(betProposal[eventNumber][4])]
}));
setGlobal('svs.components.marketplaceData.createBetProposal.helpers.deNormalizeBetProposal', deNormalizeBetProposal);

 })(window);