(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-bet-proposal/assets/javascripts/helpers/de-normalize-bet-proposal-racing.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-bet-proposal/assets/javascripts/helpers/de-normalize-bet-proposal-racing.js');
"use strict";


const deNormalizeBetProposalRacing = betProposal => Object.keys(betProposal).map(eventNumber => ({
  eventNumber,
  choices: [Boolean(betProposal[eventNumber][1]), Boolean(betProposal[eventNumber][2]), Boolean(betProposal[eventNumber][3]), Boolean(betProposal[eventNumber][4]), Boolean(betProposal[eventNumber][5])]
}));
setGlobal('svs.components.marketplaceData.createBetProposal.helpers.deNormalizeBetProposalRacing', deNormalizeBetProposalRacing);

 })(window);