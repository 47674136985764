(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-bet-proposal/assets/javascripts/helpers/de-normalize-bet-proposal-fulltraff.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-bet-proposal/assets/javascripts/helpers/de-normalize-bet-proposal-fulltraff.js');
"use strict";


const deNormalizeBetProposalFulltraff = betProposal => Object.keys(betProposal).map(eventNumber => {
  const choices = [...Array(8).keys()].map(key => betProposal[eventNumber][key] === true);
  return {
    eventNumber,
    choices
  };
});
setGlobal('svs.components.marketplaceData.createBetProposal.helpers.deNormalizeBetProposalFulltraff', deNormalizeBetProposalFulltraff);

 })(window);