(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-bet-proposal/assets/javascripts/helpers/de-normalize-bet-proposal-bomben.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-bet-proposal/assets/javascripts/helpers/de-normalize-bet-proposal-bomben.js');
"use strict";


const deNormalizeBetProposalBomben = betProposal => {
  const result = [];
  const NUMBER_OF_PARTICIPANTS_PER_EVENT = 2;
  const _addOneRowToResult = (oneBasedEventNumber, zeroBasedParticipantNumber) => {
    const zeroBasedRowNumber = (oneBasedEventNumber - 1) * NUMBER_OF_PARTICIPANTS_PER_EVENT + zeroBasedParticipantNumber;
    const currSelections = Object.entries(betProposal[oneBasedEventNumber][zeroBasedParticipantNumber]).filter(keyAndValue => Boolean(keyAndValue[1])).map(keyAndValue => keyAndValue[0]);
    result.push({
      eventNumber: zeroBasedRowNumber,
      choices: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map(key => currSelections.includes(key))
    });
  };
  const allOneBasedEventNumbers = Object.keys(betProposal).map(key => parseInt(key, 10));
  allOneBasedEventNumbers.forEach(eventNumber => {
    const currZeroBasedParticipantNumbers = Object.keys(betProposal[eventNumber]).map(key => parseInt(key, 10));
    currZeroBasedParticipantNumbers.forEach(participantNumber => _addOneRowToResult(eventNumber, participantNumber));
  });
  return result;
};
setGlobal('svs.components.marketplaceData.createBetProposal.helpers.deNormalizeBetProposalBomben', deNormalizeBetProposalBomben);

 })(window);